// src/constants/gameConstants.js

const Ad1 = require('../assets/images/Ad1.png');
const Ad2 = require('../assets/images/Ad2.png');
const bannerImage = require('../assets/images/banner.png');
const kingImage = require('../assets/images/koenig.png');
const queenImage = require('../assets/images/koenigin.png');
const LargeAd = require('../assets/images/large-ad-placeholder.png');

export const adImages = [Ad1, Ad2];

export {
  Ad1,
  Ad2,
  bannerImage,
  kingImage,
  queenImage,
  LargeAd
};

// You can add other game constants here