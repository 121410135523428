// src/services/apiService.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const api = axios.create({
  baseURL: API_URL,
});

export const fetchQuizData = async (date = null) => {
  try {
    const url = date ? `/api/quiz/${date}` : '/api/quiz';
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz data:', error);
    throw error;
  }
};

export const loadQuizData = async () => {
  try {
    const data = await fetchQuizData();
    console.log('Fetched data:', data);  // Log the fetched data

    if (!data || !data.dialogData || !data.dialogData.periodic || !data.questions) {
      throw new Error('Unexpected data structure');
    }

    return data;
  } catch (error) {
    console.error('Failed to load quiz data:', error);
    throw error;
  }
};