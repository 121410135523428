// src/App.js

import React, { useState, useEffect } from 'react';
import Banner from './components/Banner/Banner';
import GameFlow from './contexts/GameFlow';
import Slideshow from './components/Slideshow/Slideshow';
import bannerImage from './assets/images/banner.png';
import './App.css';
import { fetchQuizData } from './services/apiService';
import { updatePeriodicDialogs } from './data/dialogData';
import { storeQuizData } from './data/quizData';

import ad1 from './assets/images/Ad1.png';
import ad2 from './assets/images/Ad2.png';

const slideshowImages = [ad1, ad2];

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    const loadQuizData = async () => {
      try {
        setLoading(true);
        const data = await fetchQuizData();
        console.log('Fetched data:', data);
        console.log('Periodic dialog data:', data.dialogData.periodic);
        
        updatePeriodicDialogs(data.dialogData.periodic);
        storeQuizData(data.questions);
        
        setIsDataReady(true);
        setLoading(false);
      } catch (err) {
        console.error('Failed to load quiz data:', err);
        setError('Failed to load quiz data. Please try again later.');
        setLoading(false);
      }
    };
  
    loadQuizData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="App">
      <Banner imageSrc={bannerImage} />
      <div className="main-content">
        <GameFlow isDataReady={isDataReady} />
      </div>
      <Slideshow images={slideshowImages} />
    </div>
  );
}

export default App;