// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Slideshow/Slideshow.css */
.slideshow {
    width: 100%;
    max-width: 300px;
    aspect-ratio: 16 / 9; /* This maintains a consistent aspect ratio */
    position: relative;
    margin: 20px auto;
    overflow: hidden;
  }
  
  .slideshow img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    object-fit: contain; /* This ensures the entire image is visible */
  }
  
  .slideshow img.active {
    opacity: 1;
  }
  
  @media (min-width: 768px) {
    .slideshow {
      max-width: 400px;
    }
  }
  
  @media (min-width: 1024px) {
    .slideshow {
      max-width: 500px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Slideshow/Slideshow.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI,WAAW;IACX,gBAAgB;IAChB,oBAAoB,EAAE,6CAA6C;IACnE,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;IACV,oCAAoC;IACpC,mBAAmB,EAAE,6CAA6C;EACpE;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":["/* src/components/Slideshow/Slideshow.css */\n.slideshow {\n    width: 100%;\n    max-width: 300px;\n    aspect-ratio: 16 / 9; /* This maintains a consistent aspect ratio */\n    position: relative;\n    margin: 20px auto;\n    overflow: hidden;\n  }\n  \n  .slideshow img {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    transition: opacity 0.5s ease-in-out;\n    object-fit: contain; /* This ensures the entire image is visible */\n  }\n  \n  .slideshow img.active {\n    opacity: 1;\n  }\n  \n  @media (min-width: 768px) {\n    .slideshow {\n      max-width: 400px;\n    }\n  }\n  \n  @media (min-width: 1024px) {\n    .slideshow {\n      max-width: 500px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
