import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoolButton from '../CoolButton/CoolButton';
import './EmailCollection.css';
import Slideshow from '../Slideshow/Slideshow';

const EmailCollection = ({ onSubmit, onContinue }) => {
  const [email, setEmail] = useState('');
  const [countdown, setCountdown] = useState(5);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  const handleContinue = () => {
    if (onContinue) {
      onContinue();
    } else {
      onSubmit(null);
    }
  };

  const isMobile = windowWidth <= 768;

  return (
    <div className="interruption email-collection">
      <div className="text-content">
        <h2>Nie wieder ein Quiz verpassen!</h2>
      </div>
      <form onSubmit={handleSubmit} className="email-input">
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Deine E-Mail-Adresse"
          required
        />
      </form>
      <div className="button-container">
        <CoolButton onClick={handleSubmit} text={isMobile ? "Anmelden" : "Jetzt Anmelden"} />
        <CoolButton 
          onClick={handleContinue} 
          text={countdown > 0 ? `Weiter (${countdown})` : "Weiter"}
          disabled={countdown > 0}
        />
      </div>
      
    </div>
  );
};

EmailCollection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
};

export default EmailCollection;