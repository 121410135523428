// src/components/Interruption/AdScreen.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CoolButton from '../CoolButton/CoolButton';
import './AdScreen.css';

const AdScreen = ({ onContinue, adImage }) => {
  const [countdown, setCountdown] = useState(5);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (countdown > 0 && imageLoaded) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="ad-screen">
      <div className="ad-content">
        <div className="ad-placeholder">
          <img 
            src={adImage} 
            alt="Advertisement" 
            onLoad={handleImageLoad}
            style={{ display: imageLoaded ? 'block' : 'none' }}
          />
        </div>
        {imageLoaded && (
          <div className="countdown">
            <CoolButton 
              onClick={countdown === 0 ? onContinue : undefined} 
              text={countdown > 0 ? `Weiter (${countdown})` : "Weiter zum Quiz"} 
              disabled={countdown > 0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

AdScreen.propTypes = {
  onContinue: PropTypes.func.isRequired,
  adImage: PropTypes.string.isRequired,
};

export default AdScreen;