import React, { useState, useEffect, useMemo } from 'react';
import { kingImage, queenImage } from '../../constants/gameConstants';
import CoolButton from '../CoolButton/CoolButton';
import './Dialog.css';

function Dialog({ dialogData, onComplete }) {
  const [currentDialogIndex, setCurrentDialogIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [hasEntered, setHasEntered] = useState(false);

  // Memoize the processed dialogs array
  const dialogs = useMemo(() => {
    return Array.isArray(dialogData) && dialogData.length > 0 
      ? dialogData 
      : Object.values(dialogData).flatMap(arr => arr).filter(Boolean);
  }, [dialogData]);

  const currentDialog = dialogs[currentDialogIndex] || { character: 'king', text: 'No dialog available.' };
  const showQueen = currentDialog.character === 'queen';

  useEffect(() => {
    if (!currentDialog) {
      onComplete();
      return;
    }

    let index = 0;
    const intervalId = setInterval(() => {
      if (index < currentDialog.text.length) {
        setDisplayedText(prev => currentDialog.text.slice(0, index + 1));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [currentDialog, onComplete]);

  useEffect(() => {
    // Set hasEntered to true after a short delay
    const timer = setTimeout(() => setHasEntered(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleNext = () => {
    if (currentDialogIndex < dialogs.length - 1) {
      setCurrentDialogIndex(prev => prev + 1);
      setDisplayedText('');
    } else {
      onComplete();
    }
  };

  return (
    <div className="character-dialog">
      <div className="character-container">
        <div className={`character-wrapper ${showQueen ? 'hide' : 'show'} ${hasEntered ? 'bouncing' : ''}`}>
          <img 
            src={kingImage}
            alt="King" 
            className="character-image king"
          />
        </div>
        <div className={`character-wrapper ${showQueen ? 'show' : 'hide'} ${hasEntered ? 'bouncing' : ''}`}>
          <img 
            src={queenImage}
            alt="Queen" 
            className="character-image queen"
          />
        </div>
      </div>
      <div className="dialog-box">
        <p>{displayedText}</p>
      </div>
      <CoolButton onClick={handleNext} text="Weiter" />
    </div>
  );
}

export default Dialog;