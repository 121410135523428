// src/components/CoolButton/CoolButton.js
import React from 'react';
import './CoolButton.css';

const CoolButton = React.memo(({ onClick, text, disabled }) => (
  <div className="button-wrapper">
    <button className={`cool-button ${disabled ? 'disabled' : ''}`} onClick={onClick} disabled={disabled}>
      <span className="button-text">{text}</span>
    </button>
  </div>
));

export default CoolButton;