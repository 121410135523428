// src/contexts/GameFlow.js

import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '../components/Dialog/Dialog';
import QuizGame from '../components/QuizGame/QuizGame';
import AdScreen from '../components/Interruption/AdScreen';
import EmailCollection from '../components/Interruption/EmailCollection';
import { dialogData } from '../data/dialogData';
import { adImages, LargeAd } from '../constants/gameConstants';
import { checkAnswer, getQuizData } from '../data/quizData';

const GameFlow = ({ isDataReady }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [gameSequence, setGameSequence] = useState([]);

  useEffect(() => {
    if (isDataReady) {
      const quizQuestions = getQuizData();
      console.log('Quiz questions:', quizQuestions);
      console.log('Periodic dialogs:', dialogData.periodic);
      
      if (quizQuestions.length > 0 && Object.keys(dialogData.periodic).length > 0) {
        setQuestions(quizQuestions);
      } else {
        console.log('Data not complete. Questions:', quizQuestions.length, 'Dialogs:', Object.keys(dialogData.periodic).length);
      }
    }
  }, [isDataReady]);

  useEffect(() => {
    if (isDataReady && questions.length > 0 && Object.keys(dialogData.periodic).length > 0) {
      const sequence = [
        { type: 'DIALOG', data: dialogData.intro },
        { type: 'AD', data: LargeAd },
        { type: 'QUIZ', questionIndex: 0 },
        { type: 'DIALOG', data: dialogData.periodic[1] },
        { type: 'QUIZ', questionIndex: 1 },
        { type: 'DIALOG', data: dialogData.periodic[2] },
        { type: 'QUIZ', questionIndex: 2 },
        { type: 'DIALOG', data: dialogData.periodic[3] },
        { type: 'DIALOG', data: dialogData.eMailDialog },
        { type: 'EMAIL' },
        { type: 'QUIZ', questionIndex: 3 },
        { type: 'DIALOG', data: dialogData.periodic[4] },
        { type: 'QUIZ', questionIndex: 4 },
        { type: 'DIALOG', data: dialogData.periodic[5] },
        { type: 'QUIZ', questionIndex: 5 },
        { type: 'DIALOG', data: dialogData.periodic[6] },
        { type: 'QUIZ', questionIndex: 6 },
        { type: 'DIALOG', data: dialogData.periodic[7] },
        { type: 'DIALOG', data: dialogData.largeAdDialog },
        { type: 'AD', data: LargeAd },
        { type: 'QUIZ', questionIndex: 7 },
        { type: 'DIALOG', data: dialogData.periodic[8] },
        { type: 'QUIZ', questionIndex: 8 },
        { type: 'DIALOG', data: dialogData.periodic[9] },
        { type: 'QUIZ', questionIndex: 9 },
        { type: 'DIALOG', data: dialogData.periodic[10] },
        { type: 'DIALOG', data: dialogData.endDialog },
      ];
      setGameSequence(sequence);
    }
  }, [isDataReady, questions, dialogData]);

  useEffect(() => {
    console.log('Current step changed:', currentStep);
    console.log('Current step data:', gameSequence[currentStep]);
  }, [currentStep, gameSequence]);

  const moveToNextStep = useCallback(() => {
    console.log('Moving to next step, current step:', currentStep);
    setCurrentStep((prevStep) => prevStep + 1);
  }, [currentStep]);

  const handleQuizAnswer = useCallback((selectedAnswer) => {
    const currentQuestion = questions[gameSequence[currentStep].questionIndex];
    console.log('handleQuizAnswer called with:', selectedAnswer, 'for question:', currentQuestion);
    let isCorrect = false;

    if (selectedAnswer !== 'timeup') {
      isCorrect = checkAnswer(currentQuestion, selectedAnswer);
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
    }

    setTimeout(moveToNextStep, 3000);

    return { isCorrect };
  }, [currentStep, gameSequence, questions, moveToNextStep]);

  const renderStep = useCallback(() => {
    console.log('Current step:', currentStep, 'Game sequence length:', gameSequence.length);
    
    if (gameSequence.length === 0) {
      return <div>Loading game sequence...</div>;
    }

    if (currentStep >= gameSequence.length) {
      console.log('Game Over, all steps completed');
      return <div>Game Over</div>;
    }

    const step = gameSequence[currentStep];
    console.log('Rendering step:', step);

    switch (step.type) {
      case 'DIALOG':
        if (!step.data || step.data.length === 0) {
          console.error('Empty dialog data for step:', currentStep);
          moveToNextStep();
          return null;
        }
        return <Dialog dialogData={step.data} onComplete={moveToNextStep} />;
      case 'QUIZ':
        return (
          <QuizGame
            question={questions[step.questionIndex]}
            onAnswer={handleQuizAnswer}
            score={score}
            totalQuestions={questions.length}
          />
        );
      case 'AD':
        return <AdScreen adImage={step.data} onContinue={moveToNextStep} />;
      case 'EMAIL':
        return <EmailCollection onSubmit={moveToNextStep} />;
      default:
        console.log('Unknown step type:', step.type);
        return null;
    }
  }, [currentStep, gameSequence, questions, score, handleQuizAnswer, moveToNextStep]);

  if (!isDataReady) {
    return <div>Loading game data...</div>;
  }

  return <div>{renderStep()}</div>;
};

export default GameFlow;