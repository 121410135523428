import React, { useState, useEffect, useRef, useCallback } from 'react';
import './CSS_Test.css';

const QuizGame = ({ question, onAnswer, score, totalQuestions }) => {
    const [timeRemaining, setTimeRemaining] = useState(20);
    const [showEffect, setShowEffect] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [flashMessage, setFlashMessage] = useState(null);
    const timerRef = useRef(null);
    const answerGridRef = useRef(null);
    const intervalRef = useRef(null);
    const answerHandled = useRef(false);

    const handleAnswer = useCallback(
        (selectedAnswer) => {
            if (answerHandled.current) return;
            answerHandled.current = true;

            setSelectedAnswer(selectedAnswer);
            const result = onAnswer(selectedAnswer);
            setShowEffect(result.isCorrect ? 'correct' : 'incorrect');

            // Set flash message
            if (selectedAnswer === 'timeup') {
                setFlashMessage({ emoji: '⏰', text: 'DIE ZEIT IST AB' });
            } else if (result.isCorrect) {
                setFlashMessage({ emoji: '🎉🥳🎉', text: 'KORREKT!' });
            } else {
                setFlashMessage({ emoji: '😥', text: 'LEIDER DANEBEN!' });
            }

            const buttons = Array.from(
                answerGridRef.current.querySelectorAll('button.answer-button')
            );

            buttons.forEach((button) => {
                const answerText = button.textContent.trim();
                if (selectedAnswer === 'timeup') {
                    // When time is up, only flash the correct answer
                    if (answerText === question.correctAnswer) {
                        button.classList.add('correct-flash');
                    }
                } else if (answerText === selectedAnswer) {
                    if (result.isCorrect) {
                        button.classList.add('correct-answer-flash');
                    } else {
                        button.classList.add('incorrect-flash');
                    }
                } else if (answerText === question.correctAnswer && !result.isCorrect) {
                    button.classList.add('correct-flash');
                }
            });

            setTimeout(() => {
                setShowEffect(null);
                setSelectedAnswer(null);
                setFlashMessage(null);  // Clear flash message
                buttons.forEach((button) => {
                    button.classList.remove('correct-answer-flash', 'incorrect-flash', 'correct-flash');
                });
                answerHandled.current = false;
            }, 3000);  // Keep this at 3000ms to match the button flash duration
        },
        [question, onAnswer]
    );

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setTimeRemaining(20);
        answerHandled.current = false;

        intervalRef.current = setInterval(() => {
            setTimeRemaining((prev) => {
                if (prev > 1) return prev - 1;
                clearInterval(intervalRef.current);
                console.log('Timer reached 0, triggering handleAnswer with "timeup"');
                handleAnswer("timeup");
                return 0;
            });
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [question, handleAnswer]);

    useEffect(() => {
        if (timerRef.current) {
            if (timeRemaining > 13) {
                timerRef.current.className = 'timer-progress timer-green';
            } else if (timeRemaining > 6) {
                timerRef.current.className = 'timer-progress timer-orange';
            } else {
                timerRef.current.className = 'timer-progress timer-red';
            }
        }
        console.log('Time remaining:', timeRemaining);
    }, [timeRemaining]);

    const getResultText = (effect) => {
        if (effect === 'correct') return 'Richtig!';
        if (selectedAnswer === 'timeup') return 'Zeit abgelaufen';
        return 'Leider falsch.';
    };

    return (
        <div className="quiz-game">
            <div className="common-box timer-bar">
                <div
                    ref={timerRef}
                    className="timer-progress timer-green"
                    style={{ width: `${(timeRemaining / 20) * 100}%` }}
                ></div>
                <span className="timer-text">{timeRemaining}s</span>
            </div>
            <div className="common-box question-box">
                <h2>{question.question}</h2>
                {flashMessage && (
                    <div className="flash-message">
                        <div className="flash-message-text">
                            <span className="flash-message-emoji">{flashMessage.emoji}</span>
                            <span>{flashMessage.text}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="answer-grid" ref={answerGridRef}>
                {question.answers.map((answer) => (
                    <button
                        key={answer.id}
                        className="answer-button"
                        onClick={() => handleAnswer(answer.text)}
                        disabled={showEffect !== null || timeRemaining === 0}
                    >
                        {answer.text}
                    </button>
                ))}
            </div>
            <div className="info-section">
                <div className="score">
                    Score: {score}/{totalQuestions}
                </div>
                {showEffect && (
                    <div className={`result ${showEffect}`}>
                        {getResultText(showEffect)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuizGame;