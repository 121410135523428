// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background: white;
  }
  
  .ad-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .ad-placeholder {
    height: 60vh;
    position: relative;
    margin-bottom: 20px;
  }
  
  .ad-placeholder img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .countdown {
    /* Remove flex-grow and other positioning styles */
  }
  
  .button-wrapper {
    /* If not already applied */
    margin-top: 0;
  }
  
  @media (max-width: 768px) {
    .ad-screen {
      padding: 10px;
    }
    
    .ad-placeholder {
      margin-bottom: 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Interruption/AdScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,kDAAkD;EACpD;;EAEA;IACE,2BAA2B;IAC3B,aAAa;EACf;;EAEA;IACE;MACE,aAAa;IACf;;IAEA;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".ad-screen {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    padding: 20px;\n    box-sizing: border-box;\n    background: white;\n  }\n  \n  .ad-content {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n  \n  .ad-placeholder {\n    height: 60vh;\n    position: relative;\n    margin-bottom: 20px;\n  }\n  \n  .ad-placeholder img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n  \n  .countdown {\n    /* Remove flex-grow and other positioning styles */\n  }\n  \n  .button-wrapper {\n    /* If not already applied */\n    margin-top: 0;\n  }\n  \n  @media (max-width: 768px) {\n    .ad-screen {\n      padding: 10px;\n    }\n    \n    .ad-placeholder {\n      margin-bottom: 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
