// src/data/dialogData.js

const dialogData = {
  intro: [
    { character: 'king', text: "Malocher! Kumpel! Untertanen! ..." },
    { character: 'king', text: "Ich bin auf der Suche nach dem Quizkönig von Bochum!" },
    { character: 'queen', text: "Hömmma! Quizkönigin natürlich!" },
    { character: 'queen', text: "Quizchamp, maximal! Aber nur, wenn die Leistung stimmt! Legen wir los?" }
  ],
  eMailDialog: [
    { character: 'queen', text: "Hömma, schon drei Fragen vorbei." },
    { character: 'queen', text: "Hast du Lust, dass wir öfters quizzen?" },
    { character: 'queen', text: "Ich schreib dir gerne eine Mail, wenn das nächste Quiz bereit ist!" }
  ],
  largeAdDialog: [
    { character: 'king', text: "Stark!" },
    { character: 'king', text: "Wusstest du, dass du unter den 20% der besten Quizzer heute bist?" },
    { character: 'king', text: "Bevor wir in den Endspurt gehen, gibts eine kurze Pause für dich." },
    { character: 'king', text: "Aber bleib dran. Die Krone könnte bald dir gehören!" }
  ],
  endDialog: [
    { character: 'king', text: "Glückwunsch! Du hast das Quiz beendet." },
    { character: 'queen', text: "Möchtest du dein Ergebnis mit deinen Freunden teilen?" }
  ],
  periodic: {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: []
  }
};

const updatePeriodicDialogs = (periodicData) => {
  console.log('Received periodicData:', periodicData);
  
  Object.keys(dialogData.periodic).forEach((key) => {
    const numericKey = parseInt(key, 10);
    const character = numericKey % 2 === 1 ? 'king' : 'queen';
    
    if (periodicData[key] && periodicData[key][0] && periodicData[key][0].text) {
      const sentences = periodicData[key][0].text.match(/[^\.!\?]+[\.!\?]+/g);
      
      if (sentences) {
        dialogData.periodic[key] = sentences.map(sentence => ({
          character: character,
          text: sentence.trim()
        }));
      } else {
        dialogData.periodic[key] = [{
          character: character,
          text: periodicData[key][0].text
        }];
      }
    } else {
      console.log(`No update for key ${key} due to missing data`);
    }
  });
  
  console.log('Updated dialogData.periodic:', dialogData.periodic);
};

export { dialogData, updatePeriodicDialogs };