// src/components/Banner/Banner.js
import React from 'react';
import './Banner.css';

const Banner = ({ imageSrc }) => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <img src={imageSrc} alt="Banner" />
      </div>
    </div>
  );
};

export default Banner;