// src/data/quizData.js

let quizData = [];

export const storeQuizData = (data) => {
  quizData = data;
};

export const getQuizData = () => {
  return quizData;
};

export const checkAnswer = (question, selectedAnswer) => {
  return selectedAnswer === question.correctAnswer;
};


/* const questions = [
    {
        id: 1,
        question: "In welchem Jahr wurde die Zeche Hannover in Bochum stillgelegt?",
        answers: [
            { id: 1, text: "1973" },
            { id: 2, text: "1962" },
            { id: 3, text: "1981" },
            { id: 4, text: "1990" }
        ],
        correctAnswer: "1973"
    },
    {
        id: 2,
        question: "Wie heißt der Architekt, der das Bochumer Rathaus entworfen hat?",
        answers: [
            { id: 1, text: "Karl Roth" },
            { id: 2, text: "Emil Fahrenkamp" },
            { id: 3, text: "Fritz August Breuhaus" },
            { id: 4, text: "Dominikus Böhm" }
        ],
        correctAnswer: "Emil Fahrenkamp"
    },
    {
        id: 3,
        question: "Welcher bekannte deutsche Schriftsteller wurde in Bochum-Langendreer geboren?",
        answers: [
            { id: 1, text: "Günter Wallraff" },
            { id: 2, text: "Heinrich Böll" },
            { id: 3, text: "Bertolt Brecht" },
            { id: 4, text: "Hermann Hesse" }
        ],
        correctAnswer: "Günter Wallraff"
    },
    {
        id: 4,
        question: "Wie viele Haltestellen hat die Bochumer Stadtbahnlinie U35 insgesamt?",
        answers: [
            { id: 1, text: "21" },
            { id: 2, text: "15" },
            { id: 3, text: "26" },
            { id: 4, text: "32" }
        ],
        correctAnswer: "21"
    },
    {
        id: 5,
        question: "In welchem Jahr fand die erste Aufführung des Musicals Starlight Express in Bochum statt?",
        answers: [
            { id: 1, text: "1988" },
            { id: 2, text: "1984" },
            { id: 3, text: "1991" },
            { id: 4, text: "1986" }
        ],
        correctAnswer: "1988"
    },
    {
        id: 6,
        question: "Wie heißt der Fluss, der in den Kemnader See mündet?",
        answers: [
            { id: 1, text: "Oelbach" },
            { id: 2, text: "Haarstrang" },
            { id: 3, text: "Elbe" },
            { id: 4, text: "Seseke" }
        ],
        correctAnswer: "Oelbach"
    },
    {
        id: 7,
        question: "Welches Tier ziert das Wappen des Bochumer Stadtteils Wattenscheid?",
        answers: [
            { id: 1, text: "Schwan" },
            { id: 2, text: "Löwe" },
            { id: 3, text: "Adler" },
            { id: 4, text: "Pferd" }
        ],
        correctAnswer: "Schwan"
    },
    {
        id: 8,
        question: "Wie hoch ist der Förderturm der Zeche Zollverein Schacht XII?",
        answers: [
            { id: 1, text: "62 Meter" },
            { id: 2, text: "45 Meter" },
            { id: 3, text: "71 Meter" },
            { id: 4, text: "55 Meter" }
        ],
        correctAnswer: "62 Meter"
    },
    {
        id: 9,
        question: "Welches renommierte Tanztheater hat seinen Sitz im Bochumer Schauspielhaus?",
        answers: [
            { id: 1, text: "Tanztheater Wuppertal Pina Bausch" },
            { id: 2, text: "Sasha Waltz & Guests" },
            { id: 3, text: "Nederlands Dans Theater" },
            { id: 4, text: "Batsheva Dance Company" }
        ],
        correctAnswer: "Tanztheater Wuppertal Pina Bausch"
    },
    {
        id: 10,
        question: "In welchem Jahr wurde die Ruhr-Universität Bochum gegründet?",
        answers: [
            { id: 1, text: "1965" },
            { id: 2, text: "1958" },
            { id: 3, text: "1972" },
            { id: 4, text: "1962" }
        ],
        correctAnswer: "1962"
    }
]; */

// Helper function to shuffle an array